<template>
  <v-app class="lato-font">
    <div class="row marg-0">
      <div class="col-md-8 col-sm-12 col-12 padd-r-40 padd-t-128 padd-l-0 padd-r-0-mob">
        <div class="row marg-0 align-center">
          <span class="fsize24 clr-000000">Link bank account</span>
        </div>

        <span class="fsize16-afafaf">
          Bank account in your name from which you will transact funds for
          trading.
        </span>

        <div class="row marg-0 marg-t-36">
          <div class="col-md-6 padd-0">
            <div class="h-64">
              <div class="l-height-16 marg-b-4">
                <span class="fsize14 clr-55585a">Branch's IFSC</span>
              </div>
              <input
                type="text"
                class="w-270 h-40 bor-radius padd-8-16 border-input fsize14 box-shado w-100per"
                placeholder=""
                autocomplete="off"
                v-model="user.branchifsc"
                id="branchifsc"
                value="branchifsc"
                :class="{
                  'is-invalid': submitted && $v.user.branchifsc.$error,
                }"
              />
              <div
                v-if="submitted && $v.user.branchifsc.$error"
                class="invalid-feedback marg-0 l-height-20"
              >
                <span
                  v-if="!$v.user.branchifsc.required"
                  class="validatemessage"
                  >Please enter your Branch's IFSC</span
                >
              </div>
            </div>
            <div class="h-64 marg-t-16">
              <div class="l-height-16 marg-b-4">
                <span class="fsize14 clr-55585a">Branch MIRC code</span>
              </div>
              <input
                type="text"
                class="w-270 h-40 bor-radius padd-8-16 border-input fsize14 box-shado w-100per"
                placeholder=""
                autocomplete="off"
                v-model="user.branchmirc"
                id="branchmirc"
                value="branchmirc"
                :class="{
                  'is-invalid': submitted && $v.user.branchmirc.$error,
                }"
              />
              <div
                v-if="submitted && $v.user.branchmirc.$error"
                class="invalid-feedback marg-0 l-height-20"
              >
                <span
                  v-if="!$v.user.branchmirc.required"
                  class="validatemessage"
                  >Please enter your Branch MIRC code</span
                >
              </div>
            </div>
            <div class="h-64 marg-t-16">
              <div class="l-height-16 marg-b-4">
                <span class="fsize14 clr-55585a">Bank account number</span>
              </div>
              <input
                type="password"
                class="w-270 h-40 bor-radius padd-8-16 border-input fsize14 box-shado w-100per"
                placeholder=""
                autocomplete="off"
                v-model="user.bankaccountNo"
                id="bankaccountNo"
                value="bankaccountNo"
                :class="{
                  'is-invalid': submitted && $v.user.bankaccountNo.$error,
                }"
              />
              <div
                v-if="submitted && $v.user.bankaccountNo.$error"
                class="invalid-feedback marg-0 l-height-20"
              >
                <span
                  v-if="!$v.user.bankaccountNo.required"
                  class="validatemessage"
                  >Please enter your Bank account number</span
                >
              </div>
            </div>
            <div class="h-64 marg-t-16">
              <div class="l-height-16 marg-b-4">
                <span class="fsize14 clr-55585a"
                  >Confirm bank account number</span
                >
              </div>
              <input
                type="text"
                class="w-270 h-40 bor-radius padd-8-16 border-input fsize14 box-shado w-100per"
                placeholder=""
                autocomplete="off"
                v-model="user.confirmbank"
                id="confirmbank"
                value="confirmbank"
                :class="{
                  'is-invalid': submitted && $v.user.confirmbank.$error,
                }"
              />
              <div
                v-if="submitted && $v.user.confirmbank.$error"
                class="invalid-feedback marg-0 l-height-20"
              >
                <span
                  v-if="!$v.user.confirmbank.required"
                  class="validatemessage"
                  >Please enter your Confirm bank account number</span
                >
              </div>
            </div>
          </div>
          <div class="col-md-6 padd-t-23 padd-0">
            <div
              class="padd-t-20 padd-l-20 padd-b-20 borderclr-cbcb f-right bord-radius-3 f-right-none"
              style="width: 230px; height: 140px"
            >
              <label class="fsize14 clr-0073bc marg-0 fw-bold"
                >Indian Overseas Bank</label
              >

              <div class="fsize14 clr-000000 marg-t-14">West Anna Nagar,</div>
              <div class="fsize14 clr-000000">Chennai</div>
              <div class="fsize14 clr-000000 padd-b-20">Tamilnadu</div>
            </div>
          </div>
          <div class="row marg-0 marg-t-40 marg-b-40">
            <div class="">
              <span class="checkbox-center">
                <input type="checkbox" class="h-18 w-18" id="vehicle1" value=""
              /></span>
              <span class="fsize12 clr9b9b margin-l-12"
                >My sole country of tax residency is India. I/We have understood
                the information requirements of this form (read along with
                <span class="clr-0073bc">FATCA & CRS Instructions</span> ) and
                hereby confirm that the information provided by me/us on this
                Form is true, correct, and complete. I / We also confirm that I
                / We have read and understood the FACT & CRS Terms and
                Conditions below and hereby accept the same. Name for your
                account will be updated as per the Income Tax database due to
                Exchange regulations.</span
              >
            </div>

            <div class="marg-t-24">
              <span class="checkbox-center">
                <input type="checkbox" class="h-18 w-18" id="vehicle1" value=""
              /></span>
              <span class="fsize12 clr9b9b margin-l-12"
                >I Authorse Amoga to operate the movement of securites from my
                demat account for any obligation created at the Exchange through
                my order(s) / trade(s) as per the
                <span class="clr-0073bc">Terms & Conditions</span> the Online
                Delivery Insutruction.</span
              >
            </div>

            <div class="marg-t-24">
              <span class="checkbox-center">
                <input type="checkbox" class="h-18 w-18" id="vehicle1" value=""
              /></span>
              <span class="fsize12 clr9b9b margin-l-12"
                >I confirm and understand that my name as per the IT Department
                will taken as my default Amoga accout name, incase there is any
                mismatch of my name as per the IT department and my name as per
                the KRA database/Aadhaar/Bank account. I further confirm that
                the linked bank accounts, Aadhaar, and PAN belong to me.</span
              >
            </div>

            <div class="marg-t-24">
              <span class="checkbox-center">
                <input type="checkbox" class="h-18 w-18" id="vehicle1" value=""
              /></span>
              <span class="fsize12 clr9b9b margin-l-12"
                >I confirm to have read and understood the contents of the
                <span class="clr-0073bc"> Equity Annexure </span> and
                <span class="clr-0073bc"> Commodity Annexure</span> which
                include the below annexures:</span
              >
            </div>
          </div>
        </div>
        <div class="row marg-0 marg-t-40 marg-b-40">
          <div class="col-md-6 padd-0">
            <span class="fsize14 clr-0073bc">Equity Annexures</span>
            <div class="fsize-12 clr9b9b marg-t-16">
              Annexure A - Rights and Obligation of Stock Brokers, sub-brokers
              and clients Annexure B - Risk Disclosure Document Annexure C -
              Guidance Note annexure D - Policies and Procedures
            </div>
          </div>
          <div class="col-md-6">
            <span class="fsize14 clr-0073bc">Commodity Annexures</span>
            <div class="fsize-12 clr9b9b marg-t-16">
              Annexure P - Additional rights & obligations Annexure 2 - Guidance
              Note Annexure 3 - Risk Discolosure Document Annexure 4 - Rights
              and Obligations of members, authorised persons and clients
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-4 col-sm-12 padd-0 padd-0-30 backgroundclr-efef padd-t-1-mob">
        <div class="marg-t-44 backgroundclr-fff padd-t-20 padd-l-20 padd-b-75">
          <div class="">
            <img class="profileimg" src="..\assets\Profileicon.svg" alt="" />
            <span class="fsize14 fw-bold clr-000000 margin-l-12"
              >Avinash Kumar</span
            >
          </div>
          <div class="marg-t-24 fsize14 clr-000000">
            <img class="profileimg" src="..\assets\mail-icon.svg" alt="" />
            <span class="margin-l-12">1980-09-30</span>
            <img
              class="profileimg marg-l-40"
              src="..\assets\mail-icon.svg"
              alt=""
            />
            <span class="margin-l-12">Male</span>
          </div>
          <div class="marg-t-24">
            <img class="profileimg" src="..\assets\mail-icon.svg" alt="" />
            <span class="fsize14 clr-000000 margin-l-12">
              avinash1508@gmail.com</span
            >
          </div>
          <div class="marg-t-24">
            <img class="profileimg" src="..\assets\locationicon.svg" alt="" />
            <span class="fsize14 clr-000000 margin-l-12">
              No.45, Darshan Apartments <br /><span
                class="padd-l-20 margin-l-12"
                >Gandhi Nagar, West Mambalam,</span
              ><br /><span class="padd-l-20 margin-l-12"> T.Nagar,</span>
              Chennai - 600024.</span
            >
          </div>
        </div>
        <div class="marg-t-18">
          <span class="fsize14 clr-55585a"> Your PAN </span>
        </div>
        <div class="padd-t-6">
          <span class="clr-0073bc fsize24">KKHAQ7832T</span>
        </div>
        <div class="marg-t-18 h-44">
          <span class="fsize12 clr-000000">
            This PAN should belongs to you, the applicant.<br />
            If it does not, <span class="clr-0073bc">start over</span>.
          </span>
        </div>
        <div class="marg-t-8">
          <button
            class="btn-color fsize18 padd-0-16 bor-radius h-40 w-100per"
            @click="bankone()"
          >
            Continue
          </button>

          <router-link
            to="/bank"
            active-class="active"
            tag="button"
            exact
            class="borderclr-2e3031 fsize18 padd-0-16 bor-radius h-40 marg-l-8 clr-000000 w-100per marg-l-0-mob marg-t-12-mob"
          >
            Back</router-link
          >
        </div>
      </div>
    </div>
  </v-app>
</template>

<script>
import { required,sameAs } from "vuelidate/lib/validators";
export default {
  data() {
    return {
      user: {
        branchifsc: this.branchifsc,
        branchmirc: this.branchmirc,
        bankaccountNo: this.bankaccountNo,
        confirmbank: this.confirmbank,
      },

      branchifsc: null,
      submitted: true,
      branchmirc: null,
      bankaccountNo: null,
      confirmbank: null,
    };
  },
  validations: {
    user: {
      branchifsc: { required },
      branchmirc: { required },
      bankaccountNo: { required },
     confirmbank: { required, sameAsPassword: sameAs("bankaccountNo") },
    },
  },
  methods: {
    bankone() {
      this.submitted = true;
      //Error catch user id is invalid//
      this.loader = true;
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      this.$router.push("/webcam");
    },
  },
};
</script>

<style>
</style>